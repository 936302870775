import { defineRule } from 'vee-validate'
import i18n from '@/libs/i18n'
export const t = i18n.global.t as (key: string) => string;

export interface PasswordValidationSchema {
  password(value: string): true | string

  uppercase(value: string): true | string

  lowercase(value: string): true | string

  symbol(value: string): true | string

  minLength(value: string): true | string

  confirmPassword(value: string, password: string): true | string
}

// Define your validation schemas
export const emailValidationSchema = {
  email(value: string) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(value)) return true;
    return t('errorMessage.email')
  }
};

export const phoneValidationSchema = {
  phone(value: any) {
    if (!value) {
      return t('errorMessage.requiredTextPhone')
    }

    // Check if the value starts with a "+" and only contains numbers and the "+" sign
    if (/^\+[0-9]+$/.test(value)) {
      if (value?.length >= 9) {
        return true
      } else {
        return t('errorMessage.phone')
      }
    } else if (/^00/.test(value)) {
      return t('errorMessage.startWith00')
    } else {
      return t('errorMessage.startWithPlus')
    }
  }
}

export const servicesValidationSchema = {
  selectedServices(value: any) {
    if (!value || value.length === 0) {
      return t('errorMessage.requiredServices')
    }
    return true
  }
}

export const locationValidationSchema = {
  country(value: any) {
    if (!value) {
      return t('errorMessage.requiredCountry')
    }
    return true
  },
  city(value: any) {
    if (!value) {
      return t('errorMessage.requiredCity')
    }
    return true
  }
}

export const nameValidationSchema = {
  firstName(value: string) {
    if (value?.length >= 3) return true
    return t('errorMessage.firstName')
  },
  surname(value: string) {
    if (value?.length >= 1) return true
    return t('errorMessage.surname')
  }
}

export const companyNameValidationSchema = {
  companyName(value: any) {
    if (value?.length >= 3 && value?.length <= 20) return true
    return t('errorMessage.companyName')
  }
}

export const passwordValidationSchema: PasswordValidationSchema = {
  password(value: string) {
    if (value.length < 8) return t('errorMessage.passwordminLength')
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) return t('errorMessage.passwordsymbol')
    if (!/[A-Z]/.test(value)) return t('errorMessage.passworduppercase')
    if (!/[a-z]/.test(value)) return t('errorMessage.passwordlowercase')
    return true
  },

  minLength(value: string) {
    return value.length >= 8 || t('errorMessage.passwordminLength')
  },

  uppercase(value: string) {
    return /[A-Z]/.test(value) || t('errorMessage.passworduppercase')
  },

  lowercase(value: string) {
    return /[a-z]/.test(value) || t('errorMessage.passwordlowercase')
  },

  symbol(value: string) {
    return /[!@#$%^&*(),.?":{}|<>]/.test(value) || t('errorMessage.passwordsymbol')
  },

  confirmPassword(value: string, password: string) {
    return value === password || t('errorMessage.passwordconfirmPassword')
  }
}

export const loginPasswordValidationSchema = {
  password(value: string) {
    if (!value) return t('errorMessage.loginPassword')
    return true
  }
}

export const projectNameValidationSchema = {
  projectName(value: string) {
    if (value?.length >= 3 && value?.length <= 20) return true
    return t('errorMessage.projectName')
  }
}
export const titleValidationSchema = {
  title(value: string) {
    if (value?.length >= 3 && value?.length <= 15) return true
    return t('errorMessage.title')
  }
}
defineRule('title', (value: string) => {
  if (value?.length >= 3 && value?.length <= 15) return true
  return t('errorMessage.title')
})
defineRule('projectName', (value: string) => {
  if (value?.length >= 3 && value?.length <= 20) return true
  return t('errorMessage.projectName')
})

defineRule('flat_name_or_number', (value: any) => {
  if (value?.length >= 3) return true
  return t('errorMessage.flat_name_or_number')
})
export const firstNameOrNumberValidationSchema = {
  flat_name_or_number: 'flat_name_or_number'
}

defineRule('floors', (value: any) => {
  if (/^[0-9+]+$/.test(value)) {
    return true
  } else {
    return t('errorMessage.floors')
  }
})

export const floorsValidationSchema = {
  floors: 'floors'
}

defineRule('gross_m2', (value: any) => {
  if (/^[0-9+]+$/.test(value)) {
    return true
  } else {
    return t('errorMessage.gross_m2')
  }
})

export const grossM2ValidationSchema = {
  gross_m2: 'gross_m2'
}

defineRule('landArea', (value: any) => {
  if (/^[0-9+]+$/.test(value)) {
    return true
  } else {
    return t('errorMessage.landArea')
  }
})

export const landAreaValidationSchema = {
  landArea: 'landArea'
}

defineRule('net_m2', (value: any) => {
  if (/^[0-9+]+$/.test(value)) {
    return true
  } else {
    return t('errorMessage.net_m2')
  }
})

export const netValidationSchema = {
  net_m2: 'net_m2'
}

defineRule('list_price', (value: any) => {
  if (/^[0-9+]+$/.test(value)) {
    return true
  } else {
    return t('errorMessage.list_price')
  }
})

export const listPriceValidationSchema = {
  list_price: 'list_price'
}

defineRule('public_price', (value: any) => {
  if (/^[0-9+]+$/.test(value)) {
    return true
  } else {
    return t('errorMessage.public_price')
  }
})

export const publicPriceValidationSchema = {
  public_price: 'public_price'
}

defineRule('property_type', (value: string) => {
  if (!value) return t('errorMessage.property_type')
  return true
})

export const propertyTypeValidationSchema = {
  property_type: 'property_type'
}

defineRule('property_status', (value: string) => {
  if (!value) return t('errorMessage.property_status')
  return true
})

export const propertyStatusValidationSchema = {
  property_status: 'property_status'
}

defineRule('address', (value: string) => {
  if (!value) return t('errorMessage.address')
  return true
})

export const addressValidationSchema = {
  address: 'address'
}
export const blockValidationSchema = {
  numberOfBlocksProxy: 'numberOfBlocksProxy'
}

defineRule('numberOfBlocksProxy', (value: any) => {
  if (/^[0-9+]+$/.test(value)) {
    return true
  } else {
    return t('errorMessage.numberOfBlocksProxy')
  }
})

export const propertiesValidationSchema = {
  numberOfProperties(value: any) {
    if (!isNaN(value) && Number.isInteger(Number(value))) {
      return true
    }
    return t('errorMessage.numberOfProperties')
  }
}

export const phaseValidationSchema = {
  numberOfPhases: 'numberOfPhases'
}

defineRule('numberOfPhases', (value: any) => {
  if (/^[0-9+]+$/.test(value)) {
    return true
  } else {
    return t('errorMessage.numberOfPhases')
  }
})

defineRule('selectedRealEstateType', (value: string) => {
  if (!value) return t('errorMessage.selectedRealEstateType')
  return true
})

export const selectedRealEstateTypeValidationSchema = {
  selectedRealEstateType: 'selectedRealEstateType'
}

export const numericValidationSchema = {
  numberOfProperties(value: any) {
    if (!value) return true; 

    if (/^\d+$/.test(value)) {
      return true; 
    }

    return t('errorMessage.numeric');
  },
};

export const lettersValidationSchema = {
  letter(value: any) {
    if (!value) return true; 

    if (/^[a-zA-Z\s]*$/.test(value)) {
      return true; 
    }

    return t('errorMessage.letter');
  },
};

defineRule('numeric', (value: any) => {
  if (value === null || value === undefined) {
    return true
  }
  if (/^[0-9]+$/.test(value)) {
    return true
  }
  return t('errorMessage.numeric')
})

// Define validation schemas
export const priceValidationSchema = {
  startingPrice(value: number | null) {
    if (value === null) return true
    if (/^[0-9,]+$/.test(value.toString())) return true
    return t('errorMessage.startingPrice')
  },
  maximumPrice(value: number | null) {
    if (value === null) return true
    if (/^[0-9,]+$/.test(value.toString())) return true
    return t('errorMessage.maximumPrice')
  },
  fixedPrice(value: number | null) {
    if (value === null) return true
    if (/^[0-9,]+$/.test(value.toString())) return true
    return t('errorMessage.fixedPrice')
  }
}

defineRule('contactNumber', (value: string | null) => {
  if (!value) return t('errorMessage.contactNumberrequired')
  if (/^[0-9]+$/.test(value)) return true
  return t('errorMessage.contactNumberdigits')
})

defineRule('companyEmail', (value: string | null) => {
  if (!value) return t('errorMessage.companyEmailrequired')
  if (/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(value)) return true
  return t('errorMessage.companyEmailvalidFormat')
})

export const contactNumberValidationSchema = {
  companyNumber(value: any) {
    if (/^[0-9+]+$/.test(value)) {
      if (value?.length >= 9) {
        return true
      } else {
        return t('errorMessage.phone')
      }
    } else {
      return t('errorMessage.startWithPlus')
    }
  }
}

export const companyEmailValidationSchema = {
  companyEmail(value: string | null) {
    if (!value) return t('errorMessage.requiredTextEmail')
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(value)) return true;
    return t('errorMessage.otpEmail')
  }
};

export const youtubeLinkValidationSchema = {
  youtubeLink(value: string | null) {
    if (!value) {
      return true;
    }
    const youtubeRegex = /(?:https?:\/\/|www\.|m\.|^)youtu(?:be\.com\/watch\?(?:.*?&(?:amp;)?)?v=|\.be\/)([\w\-]+)(?:&(?:amp;)?[\w\?=]*)?/;
    if (youtubeRegex.test(value)) {
      return true;
    }
    return t('errorMessage.youtube');
  }
};

export const positionValidationSchema = {
  position(value: any) {
    if (value?.length >= 2) return true
    return t('errorMessage.position')
  }
}
export const emailOtpValidationSchema = {
  otpEmail(value: string) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(value)) return true;
    return t('errorMessage.otpEmail')
  }
};
defineRule('otpEmail', (value: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (emailRegex.test(value)) {
    return true;
  }
  return t('errorMessage.otpEmail')
});

export const commissionValidationSchema = {
  commission: 'commission'
}

defineRule('commission', (value: any) => {
  if (/^[0-9+]+$/.test(value)) {
    return true
  } else {
    return t('errorMessage.commission')
  }
})
